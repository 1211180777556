var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("a-table", {
        staticClass: "gx-table-responsive",
        attrs: {
          columns: _vm.columns,
          pagination: _vm.pagination,
          "row-key": (record) => record.id,
          "data-source": _vm.schedule,
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "work_email",
            fn: function (text) {
              return _c(
                "div",
                { staticClass: "email-block" },
                [
                  [
                    _vm._v(_vm._s(text) + "\n        "),
                    _c("a-icon", {
                      staticClass: "gx-ml-1",
                      staticStyle: { cursor: "pointer" },
                      attrs: { type: "copy", theme: "twoTone" },
                      on: {
                        click: function ($event) {
                          return _vm.copyEmail(text)
                        },
                      },
                    }),
                  ],
                ],
                2
              )
            },
          },
          {
            key: "phone",
            fn: function (text) {
              return _c(
                "div",
                {},
                [text ? [_vm._v("+353 " + _vm._s(text))] : [_vm._v("N/A")]],
                2
              )
            },
          },
          {
            key: "status",
            fn: function (text) {
              return _c(
                "div",
                { staticClass: "status_data" },
                [
                  text === "accept"
                    ? _c(
                        "a-tag",
                        {
                          staticStyle: {
                            "margin-bottom": "0px",
                            "border-radius": "30px",
                          },
                          attrs: { color: "#27ae60" },
                        },
                        [_vm._v("Active")]
                      )
                    : _vm._e(),
                  text === "invite"
                    ? _c(
                        "a-tag",
                        {
                          staticStyle: {
                            "margin-bottom": "0px",
                            "border-radius": "30px",
                          },
                          attrs: { color: "#f39c12" },
                        },
                        [_vm._v("Invite Pending")]
                      )
                    : _vm._e(),
                  text === "request"
                    ? _c(
                        "a-tag",
                        {
                          staticStyle: {
                            "margin-bottom": "0px",
                            "border-radius": "30px",
                          },
                          attrs: { color: "#8e44ad" },
                        },
                        [_vm._v("Has Requested to Join")]
                      )
                    : _vm._e(),
                  text === "cancel"
                    ? _c(
                        "a-tag",
                        {
                          staticStyle: {
                            "margin-bottom": "0px",
                            "border-radius": "30px",
                          },
                          attrs: { color: "#8e44ad" },
                        },
                        [_vm._v("No Longer a Member")]
                      )
                    : _vm._e(),
                  text === "archive"
                    ? _c(
                        "a-tag",
                        {
                          staticStyle: {
                            "margin-bottom": "0px",
                            "border-radius": "30px",
                          },
                          attrs: { color: "#d35400" },
                        },
                        [_vm._v("Archived")]
                      )
                    : _vm._e(),
                  text === "decline"
                    ? _c(
                        "a-tag",
                        {
                          staticStyle: {
                            "margin-bottom": "0px",
                            "border-radius": "30px",
                          },
                          attrs: { color: "#c0392b" },
                        },
                        [_vm._v("Declined Invitation")]
                      )
                    : _vm._e(),
                ],
                1
              )
            },
          },
          {
            key: "handlers",
            fn: function (text, record) {
              return _c(
                "div",
                {
                  staticClass: "gx-text-right",
                  staticStyle: { display: "flex" },
                  attrs: { type: "flex" },
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/club/member/" + record.user_id } },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: {
                            "margin-right": "5px",
                            "margin-bottom": "0px",
                          },
                          attrs: { size: "small", type: "primary" },
                        },
                        [_vm._v("View Profile")]
                      ),
                    ],
                    1
                  ),
                  record.status === "accept"
                    ? _c(
                        "a-button",
                        {
                          staticStyle: {
                            color: "#f5222d",
                            "background-color": "#f5f5f5",
                            "border-color": "#d9d9d9",
                            "margin-right": "5px",
                            "margin-bottom": "0px",
                          },
                          attrs: { size: "small", type: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.updateClubMember(
                                record.id,
                                record.club_id,
                                "archive"
                              )
                            },
                          },
                        },
                        [_vm._v("Archive")]
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      staticStyle: {
                        color: "#f5222d",
                        "background-color": "#f5f5f5",
                        "border-color": "#d9d9d9",
                        "margin-right": "5px",
                        "margin-bottom": "0px",
                      },
                      attrs: { size: "small", type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteClubMember(record.id)
                        },
                      },
                    },
                    [_vm._v("Delete")]
                  ),
                  record.status === "accept"
                    ? _c(
                        "a-button",
                        {
                          staticStyle: {
                            color: "#f5222d",
                            "background-color": "#f5f5f5",
                            "border-color": "#d9d9d9",
                            "margin-bottom": "0px",
                          },
                          attrs: { size: "small", type: "danger" },
                          on: {
                            click: function ($event) {
                              ;(_vm.searchTeamModal = true),
                                (_vm.selectMemberId = record.id)
                            },
                          },
                        },
                        [_vm._v("Add to Team")]
                      )
                    : _vm._e(),
                  record.status !== "accept" &&
                  record.status !== "invite" &&
                  record.status !== "decline" &&
                  record.status !== "request" &&
                  record.status !== "cancel"
                    ? _c(
                        "a-button",
                        {
                          staticClass: "gx-btn-cyan",
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.updateClubMember(
                                record.id,
                                record.club_id,
                                "accept"
                              )
                            },
                          },
                        },
                        [_vm._v("Make Active")]
                      )
                    : _vm._e(),
                  record.status === "request"
                    ? _c(
                        "a-button",
                        {
                          staticClass: "gx-btn-cyan",
                          staticStyle: { "margin-bottom": "0px" },
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.updateClubMember(
                                record.id,
                                record.club_id,
                                "accept"
                              )
                            },
                          },
                        },
                        [_vm._v("Accept Request")]
                      )
                    : _vm._e(),
                  record.status === "cancel"
                    ? _c(
                        "a-button",
                        {
                          staticStyle: { "margin-bottom": "0px" },
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.updateClubMember(
                                record.id,
                                record.club_id,
                                "invite"
                              )
                            },
                          },
                        },
                        [_vm._v("Send Invite")]
                      )
                    : _vm._e(),
                  record.status === "invite"
                    ? _c(
                        "a-button",
                        {
                          staticClass: "gx-btn-red gx-fs-sm",
                          staticStyle: { "margin-bottom": "0px" },
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.updateClubMember(
                                record.id,
                                record.club_id,
                                "cancel"
                              )
                            },
                          },
                        },
                        [_vm._v("Cancel Invite")]
                      )
                    : _vm._e(),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }